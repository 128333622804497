import { WidgetItem, WidgetList, WidgetResults } from '@Services/types/widgets'
import http from '../http'
import { API_URL } from './urls'
import {
  AgentStatsParams,
  AgentStatsResonse,
  SalesCampaignReportItem,
  SalesCampaignReportResponse,
} from '@Services/types'

export const DashboardApi = {
  getWidgets: async function (): Promise<WidgetResults> {
    return await http.get(API_URL.dashboardWidgets)
  },
  getWidgetList: async function (): Promise<WidgetList> {
    return await http.get(API_URL.dashboardWidgetList)
  },
  createWidget: async function (
    data: Partial<WidgetItem>
  ): Promise<WidgetItem> {
    return await http.post(API_URL.dashboardWidgets, data)
  },
  updateWidget: async function (
    data: Partial<WidgetItem>
  ): Promise<WidgetItem> {
    return await http.patch(`${API_URL.dashboardWidgets}${data.id}/`, data)
  },
  removeWidget: async function (id: number): Promise<any> {
    return await http.delete(`${API_URL.dashboardWidgets}${id}`)
  },
  agentStats: async function (
    params: AgentStatsParams
  ): Promise<AgentStatsResonse> {
    return await http.get(`${API_URL.agentStats}`, { params })
  },
  eventReport: async function (params: {}): Promise<any> {
    return await http.get(API_URL.eventReport, { params })
  },

  salesCampaignReport: async function (
    params: Record<string, any>
  ): Promise<SalesCampaignReportResponse> {
    return await http.get(`${API_URL.salesCampaignReport}`, { params })
  },

  salesCampaignReportDetails: async function (
    id: number | string
  ): Promise<SalesCampaignReportItem> {
    return await http.get(`${API_URL.salesCampaignReport}${id}`)
  },
}
