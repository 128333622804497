import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const Loading = styled.div<{ backdropBlur?: boolean }>`
  ${tw`absolute w-full h-full flex flex-col justify-center items-center text-primary text-sm top-0 left-0 z-50`}
  ${({ backdropBlur }) =>
    backdropBlur &&
    css`
      ${tw`absolute w-full h-full`}
      background-color: rgba(229, 243, 246, 0.3);
    `}
`

export const LoadingIcon = styled.div`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  ${tw`border border-4 border-primaryLight w-10 h-10 rounded-full mb-2 relative z-10`}
  border-top-color: #05386B;
  animation: spin 1s linear infinite;
`

export const LoadingMessage = styled.span`
  ${tw`block text-center`}
`
