import useDashboardWidgets from '@Hooks/useDashboardWidgets'
import { WidgetItem } from '@Services/types/widgets'
import { useRouter } from 'next/router'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

export const defaultWidgetContext = {
  widgets: [],
  setWidgets: () => {},
  setReloadWidgets: () => {},
}

type WidgetContextType = {
  widgets: WidgetItem[]
  setWidgets: (data?: WidgetItem[]) => void
  setReloadWidgets: (shouldReload: boolean) => void
}

export const WidgetContext =
  createContext<WidgetContextType>(defaultWidgetContext)

export const useWidgetContext = () => useContext(WidgetContext)

interface WidgetProviderProps {
  children: ReactNode
}

export const WidgetProvider = ({ children }: WidgetProviderProps) => {
  const [reloadWidgets, setReloadWidgets] = useState(false)
  const [widgets, setWidgets] = useState<WidgetItem[]>([])
  const { widgets: data } = useDashboardWidgets(reloadWidgets, setReloadWidgets)
  const router = useRouter()

  useEffect(() => {
    data.forEach((it, key) => (it.order = key + 1))
    setWidgets(data)
  }, [data])

  useEffect(() => {
    setReloadWidgets(true)
  }, [router.asPath])

  return (
    <WidgetContext.Provider value={{ widgets, setWidgets, setReloadWidgets }}>
      {children}
    </WidgetContext.Provider>
  )
}

export default WidgetContext
