export const colors = {
  greyDarker: '#323B4B',
  blueBayoux: '#4E5D78',
  grey: '#8A94A6',
  greyLight: '#B0B7C3',

  // background
  lavenderGrey: '#C1C7D0',
  azureishWhite: '#E2E8F0',
  concrete: '#F3F3F3',
  ghostWhite: '#FAFBFC',
  white: '#FFFFFF',

  primary: '#247083',
  secondary: '#E2E8F0',
  success: '#36BEEE',
  warning: '#FFAB00',
  danger: '#F93866',

  primaryLight: '#E5F3F6',
  primaryLighter: '#F4F8FF',

  successLight: '#E5F6EF',
  successLighter: '#F5FCF9',

  warningLight: '#FDF4E0',
  warningLighter: '#FFFBF1',
  warningDark : '#F4D161',

  dangerLight: '#FFEFEB',
  dangerLighter: '#FFF6F4',
  dangerMedium: '#F26F68',

  // input
  input: '#C1C7D0',
}
