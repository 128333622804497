import { createContext } from 'react'
import { User } from '../services/types'

export const DEFAULT_USER_CONTEXT = {
  username: null,
  email: null,
  name: null,
  display_image: null,
  display_name: null,
  url: null,
  agents_offices: null,
  isLogout: false,
  social_accounts: null,
  is_admin: false,
}

type UserContextType = {
  user: User | null
  setUser?: (value: User | null) => void
}

export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {},
})
