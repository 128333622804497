const BASEURL =
  process.env.NEXT_PUBLIC_API_ENDPOINT || 'https://uat.app.banto.com.au' // Staging

const API_BASE = `${BASEURL}/api`

export const API_URL = {
  login: `${API_BASE}/auth/login/`,
  google_login: `${API_BASE}/google`,
  logout: `${API_BASE}/auth/logout/`,
  authSocialSettings: `${API_BASE}/auth/social/settings/`,
  authSocial: `${API_BASE}/auth/social/`,
  resetPassword: `${API_BASE}/auth/password/reset/`,
  facebookLogin: `${API_BASE}/auth/facebook/`,
  googleLogin: `${API_BASE}/auth/google/`,
  linkedinLogin: `${API_BASE}/auth/linkedin/`,
  facebookConnect: `${API_BASE}/auth/facebook/connect/`,
  googleConnect: `${API_BASE}/auth/google/connect/`,
  linkedinConnect: `${API_BASE}/auth/linkedin/connect/`,
  confirmResetPassword: `${API_BASE}/auth/password/reset/confirm/`,
  updatePassword: `${API_BASE}/auth/password/change/`,
  users: `${API_BASE}/users/`,
  staff: `${API_BASE}/staff/`,
  actions: `${API_BASE}/actions/`,
  staffTeams: `${API_BASE}/staff/teams/`,
  staffPositions: `${API_BASE}/staff/positions/`,
  listings: `${API_BASE}/listings/`,
  listingMedia: `${API_BASE}/listings/media/`,
  listingDocuments: `${API_BASE}/listings/documents/`,
  listingsAutocomplete: `${API_BASE}/listings/autocomplete/`,
  listingsGrowthMetrics: `${API_BASE}/listings/growth-metrics/`,
  addresses: `${API_BASE}/addresses/`,
  addAddress: `${API_BASE}/add-address/`,
  offices: `${API_BASE}/offices/`,
  notifications: `${API_BASE}/dashboard/notifications/`,
  widgets: `${API_BASE}/dashboard/widgets/`,
  history: `${API_BASE}/history/`,
  geoAutocomplete: `${API_BASE}/geo-autocomplete/`,
  agentCard: `${API_BASE}/agent-cards/`,
  marketing: `${API_BASE}/marketing/widgets/`,
  micrositesListings: `${API_BASE}/microsites/listings/`,
  micrositesTemplates: `${API_BASE}/microsites/templates/`,
  micrositesFiles: `${API_BASE}/microsites/files/`,
  micrositesImages: `${API_BASE}/microsites/images/`,
  micrositesFloorplans: `${API_BASE}/microsites/floorplans/`,
  micrositesLinks: `${API_BASE}/microsites/links/`,
  customFields: `${API_BASE}/microsites/custom-fields/`,
  domainAvailabilityList: `${API_BASE}/microsites/domain-availability/{id}/suggest/listing/`,
  outboundPushListing: `${API_BASE}/outbound-streams/{id}/push-listing/`,
  listingLogs: `${API_BASE}/outbound-streams/{id}/listing-logs/`,
  checkDomainAvailability: `${API_BASE}/microsites/domain-availability/check/`,
  leads: `${API_BASE}/leads/`,
  suburbAutocomplete: `${API_BASE}/suburb-autocomplete/`,
  mediaLibrary: `${API_BASE}/media-library/`,
  websiteContact: `${API_BASE}/website/contact/`,
  dashboardWidgets: `${API_BASE}/dashboard/widgets/`,
  dashboardWidgetList: `${API_BASE}/dashboard/widget-list/`,
  dashboardWidgetsData: `${API_BASE}/dashboard/widgets/data/`,
  agentStats: `${API_BASE}/dashboard/agent-stats/`,
  eventReport:  `${API_BASE}/dashboard/event-report/`,
  salesCampaignReport: `${API_BASE}/dashboard/sales-campaign-report/`,
  geocodeAddress: `${API_BASE}/geocode-address/`,
}
