import { useEffect, useState } from 'react'
import { getCookie } from '../services/cookies'
import { addAuthorizationHeader } from '../utils/token'
import { DashboardApi } from '@Services/api/dashboard'
import { WidgetItem } from '@Services/types/widgets'

const useDashboardWidgets = (
  reloadWidget?: boolean,
  setReloadWidgets?: (val: boolean) => void
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [widgets, setWidgets] = useState<WidgetItem[]>([])

  useEffect(() => {
    const token = getCookie('token')
    token && addAuthorizationHeader(token)

    const getDashboardWidgets = async () => {
      try {
        setIsLoading(true)
        const { results } = await DashboardApi.getWidgets()
        setWidgets(results)
        setIsLoading(false)
        setReloadWidgets && setReloadWidgets(false)
      } catch (e) {
        setIsLoading(false)
        setReloadWidgets && setReloadWidgets(false)
      }
    }

    if (reloadWidget === undefined || reloadWidget) {
      getDashboardWidgets()
    }
  }, [reloadWidget])

  return { widgets, isLoading }
}

export default useDashboardWidgets
