import styled from 'styled-components'
import tw from 'twin.macro'
import { colors } from './theme'
import { DEVICES } from '@Constants/global'

export const FormGroup = styled.div<{
  center?: boolean
  flex?: boolean
  justifyCenter?: boolean
  noMbXl?: boolean
  mtXl?: string
}>`
  ${tw`mb-6 w-full`}
  ${tw`mobile:(mb-4) w-full`}

  > div {
    ${tw`mb-0`}
  }

  ${({ flex }) => flex && tw`flex mobile:(flex-col justify-start)`}
  ${({ center }) => center && tw`items-center`}
  ${({ justifyCenter }) => justifyCenter && tw`justify-center`}
  ${({ noMbXl }) => noMbXl && tw`xl:mb-0`}

  @media ${DEVICES.xl} {
    margin-top: ${({ mtXl }) => (mtXl ? mtXl : '')};
  }

  .icon-clear svg {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
`

export const TooltipWrap = styled.span`
  ${tw`inline-block`}
`

export const Label = styled.label`
  ${tw`flex items-center mb-1.5 text-sm text-blueBayoux font-semibold w-full`}

  ${TooltipWrap} {
    ${tw`ml-2`}
  }
`

export const UppercaseFirstChar = styled.span`
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const SeclectStyles = (isSimpleAddressModal?: boolean) => {
  return {
    container: (styles) => ({
      ...styles,
      flex: 1,
      height: '100%',
    }),
    control: (styles) => ({
      ...styles,
      height: '100%',
      boxShadow: 'none',
      borderRadius: '5px',
      border: `1px solid ${colors.input}`,
      padding: isSimpleAddressModal
        ? '0.5rem 0.875rem 0.5rem 2.25rem'
        : '0.5rem 0.875rem 0.5rem 0.5rem',
      minHeight: 58,
      ':hover, :focus': {
        border: `1px solid ${colors.input}`,
      },
      '&.error__control': {
        border: `1px solid ${colors.danger}`,
      },
    }),
    input: (styles) => ({
      ...styles,
      color: colors.greyDarker,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: colors.greyDarker,
      '&.error__single-value': {
        color: colors.danger,
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: colors.greyLight,
    }),
    clearIndicator: (styles) => ({
      ...styles,
      color: colors.greyLight,
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: colors.primary,
      borderRadius: 50,
      alignItems: 'center',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingTop: '0.3125rem',
      paddingBottom: '0.3125rem',
      margin: '0 6px 0 0',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: colors.white,
      fontSize: 12,
      lineHeight: '1rem',
      padding: '0 !important',
    }),
    multiValueRemove: () => ({
      display: 'inline-block',
      width: 16,
      height: 16,
      marginLeft: 5,
      color: colors.white,
      cursor: 'pointer',
      backgroundImage: `url('/static/icons/close-white.svg')`,
      backgroundSize: 'contain',
      '& svg': {
        display: 'none',
      },
    }),
    indicatorsContainer: (styles, state) => ({
      ...styles,
      '& p': {
        cursor: 'pointer',
      },
      '& > div': {
        padding: 6,
      },
      '& svg': {
        transform: state.isFocused ? 'rotate(-180deg)' : 'nonw',
        transition: 'all 0.3s',
      },
    }),
  }
}
